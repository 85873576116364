






























import ProfileCard from './components/ProfileCard.vue'
import mixins from 'vue-typed-mixins'
import Employees from '@/builder/sections/section/employees/mixins/Employees'
export default mixins(Employees).extend({
  name: 'Employees4',
  components: { ProfileCard }
})
