<template>
  <v-card
    class="mx-auto"
    elevation="4"
    min-width="300"
    max-width="400"
    min-height="150"
    height="100%"
    color="wb_color_bg_2__color__"
  >
    <v-list-item :class="{ 'profile-description' : socialMediaList && socialMediaList.length > 0 }">
      <v-list-item-avatar
        tile
        size="96"
        color="grey"
        class="rounded-circle"
      >
        <v-img :src="avatar" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-subtitle-1 wb_color_text_2__color__--text mb-2">
          {{ fullName }}
        </v-list-item-title>
        <div class="text-body-2">
          {{ profession }}
        </div>
        <div
          class="text-body-2 wb_color_text_2__color__--text mb-2"
        >
          {{ description }}
        </div>
      </v-list-item-content>
    </v-list-item>
    <template v-if="socialMediaList && socialMediaList.length > 0">
      <div class="profile-social-list">
        <v-divider />
        <v-card-actions class="justify-end">
          <template v-for="(socialMedia, index) in socialMediaList">
            <v-btn
              v-if="socialMedia.href"
              :key="index"
              icon
              target="_blank"
              :href="socialMedia.href"
            >
              <v-icon color="wb_color_primary__color__">
                {{ socialMedia.icon }}
              </v-icon>
            </v-btn>
          </template>
        </v-card-actions>
      </div>
    </template>
  </v-card>
</template>

<script>
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'

export default mixins(DraftElement).extend({
  name: 'ProfileCard',
  props: {
    avatar: {
      type: String
    },
    fullName: {
      type: String,
      required: true
    },
    profession: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    socialMediaList: {
      type: Array,
      default: () => []
    }
  }
})
</script>

<style lang="scss" scoped>

.profile-description {
  padding-bottom: 80px;
}

.profile-social-list {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.v-avatar {
  align-self: flex-start !important;
  margin-top: 16px;
}

.v-card__actions{
  padding: 20px;
}

.text-body-2 {
  color: var(--v-wb_color_text_2__color__-lighten3)
}
</style>
